<template>
  <div id="new-lgb-team-members-modifiy">
    <v-dialog v-model="memberDocumentPrompt" persistent scrollable>
      <upload-selected-member-document
        :record="SelectedMember"
        v-if="memberDocumentPrompt"
        :showDialog="memberDocumentPrompt"
        @hideDialog="hideMemberDocumentPrompt($event)"
      ></upload-selected-member-document>
    </v-dialog>
    <v-dialog v-model="memberPhotoPrompt" persistent scrollable>
      <upload-selected-member-photo
        :record="SelectedMember"
        v-if="memberPhotoPrompt"
        :showDialog="memberPhotoPrompt"
        @hideDialog="hideMemberPhotoPrompt($event)"
      ></upload-selected-member-photo>
    </v-dialog>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageDescription }} {{ NextYearName }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-card-title class="pa-0">
              <!-- <span class="headline"></span> -->
              <!-- <v-toolbar flat>
                <v-toolbar-title>{{ pageDescription }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closePrompt">
                  <v-icon class="heading grey--text text--darken-4"
                    >close</v-icon
                  >
                </v-btn>
              </v-toolbar> -->
            </v-card-title>

            <v-card-text style="height: 70%">
              <v-container class="py-0">
                <div
                  class="d-flex justify-content-center mb-3"
                  v-if="LoadingFlag"
                >
                  <b-spinner
                    variant="primary"
                    type="grow"
                    label="Loading..."
                  ></b-spinner>
                </div>
                <v-container v-if="StartupLoadingFlag">
                  <v-row wrap>
                    <v-col cols="12" md="4" v-for="n in 6" :key="n">
                      <v-skeleton-loader
                        v-bind="attrs"
                        type="paragraph"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row wrap v-if="ProgessStart == 1">
                  <v-col align="center" cols="12" md="12">
                    <v-progress-circular
                      :rotate="180"
                      :size="100"
                      :width="15"
                      :value="uploadPercentage"
                      color="#00a4ef"
                    >
                      {{ uploadPercentage }}
                    </v-progress-circular>
                    <h3 class="red--text">
                      Please wait, we are uploading your details. Time may vary
                      based on your internet connection.
                    </h3>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="!StartupLoadingFlag">
                <v-row wrap>
                  <v-col cols="12" md="12" align="center">
                <h3>
                  <span class="text-danger">*</span> indicates required field
                </h3>
              </v-col>
                  <v-col cols="12" md="6">
                    <h6 class="mycard-text1 fsize-4">
                      <span class="text-danger">*</span> Designation
                    </h6>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="DesignationIdOptionsLoading"
                      :items="DesignationIdOptions"
                      :rules="DesignationIdRules"
                      v-model="DesignationId"
                      required
                      outlined
                      dense
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <h6 class="mycard-text1 fsize-4">
                      <span class="text-danger">*</span> Member
                    </h6>
                    <v-autocomplete
                      @change="getMemberDetails"
                      :reduce="(option) => option.value"
                      :loading="MemberIdOptionsLoading"
                      :items="MemberIdOptions"
                      :rules="MemberIdRules"
                      v-model="MemberId"
                      required
                      outlined
                      dense
                      clearable
                    >
                      <!-- :disabled="index == 0 ? true : false" -->
                      <template v-slot:selection="{ item }">
                        <img width="50" height="50" :src="item.photo" />{{
                          item.text
                        }}
                      </template>
                      <template v-slot:item="{ item }">
                        <img width="50" height="50" :src="item.photo" />{{
                          item.text
                        }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="MemberId > 0">
                  <v-col cols="12" sm="6" md="3">
                    <h4><span class="text-danger">*</span> Date of Birth</h4>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          required
                          v-model="SelectedMember.member_dob"
                          label="From Date"
                          hint="Select a From Date"
                          persistent-hint
                          readonly
                          v-on="on"
                          background-color="#F3F6F9"
                          class="form-control form-control-lg form-control-solid"
                          validate-on-blur
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="SelectedMember.member_dob"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <h4>Profile Update</h4>
                    <v-chip
                      :color="`${
                        SelectedMember.profile_updated == 1
                          ? '#0f9d58'
                          : '#f4b400'
                      }`"
                      draggable
                      dark
                      >{{
                        SelectedMember.profile_updated == 1
                          ? "Completed"
                          : "Pending"
                      }}
                    </v-chip>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <h4><span class="text-danger">*</span> Photo Update</h4>
                    <v-chip
                      :color="`${
                        SelectedMember.photo_flag ? '#0f9d58' : '#f4b400'
                      }`"
                      draggable
                      dark
                      >{{ SelectedMember.photo_flag ? "Completed" : "Pending" }}
                    </v-chip>
                    &nbsp;
                    <v-icon
                      icon
                      size="25px"
                      color="primary"
                      @click.prevent="memberPhotoPrompt = true"
                      v-if="SelectedMember.photo_flag == false"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <h4><span class="text-danger">*</span> Age Proof Document Upload</h4>
                    <v-chip
                      :color="`${
                        SelectedMember.document_flag ? '#0f9d58' : '#f4b400'
                      }`"
                      draggable
                      dark
                      >{{
                        SelectedMember.document_flag ? "Completed" : "Pending"
                      }}
                    </v-chip>
                    &nbsp;
                    <v-icon
                      icon
                      size="25px"
                      color="primary"
                      @click.prevent="memberDocumentPrompt = true"
                      v-if="SelectedMember.document_flag == false"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
              <p class="notes-title">Important:</p>
              <ol class="notes-content">
                <li>
                  The elected member should have completed their profile
                  updation in their Members Portal.
                </li>
                <li>
                  The elected member should have their photo uploaded in their
                  profile or you can do it from here by clicking the upload
                  button.
                </li>
                <li>
                  The elected member should have uploaded their age proof
                  document in their Members Portal or you can do it from here by
                  clicking the upload button.
                </li>
              </ol>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="closePrompt"
                elevation="30"
                shaped
                tile
                small
                color="#a52a2a"
                class="font-size-h6 px-10 mr-3 white--text"
              >
                Close
              </v-btn>
              <v-btn
                :disabled="!valid1"
                @click.prevent="confirmAlert"
                :loading="SubmitFlag"
                elevation="30"
                shaped
                tile
                small
                color="#1db954"
                class="font-size-h6 px-10 mr-3 white--text"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import UploadSelectedMemberDocument from "@/view/pages/members/UploadSelectedMemberDocument.vue";
import UploadSelectedMemberPhoto from "@/view/pages/members/UploadSelectedMemberPhoto.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    UploadSelectedMemberDocument,
    UploadSelectedMemberPhoto,
  },
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,

      menu1: false,
      SelectedMember: {},

      memberDocumentPrompt: false,
      memberPhotoPrompt: false,

      search: "",
      selected: [],
      tableSelectFlag1: false,
      SubmitFlag: false,

      rows: [],
      tableColumns1: [],
      records: [],

      alert: {},

      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      ResultFlag: false,
      LoadingFlag: false,
      StartupLoadingFlag: false,

      AllMembers: [],

      LomCode: "",
      CurrentYearId: "",

      YearCodeRules: [(v) => !!v || "Year is required"],
      YearCode: "",
      YearCodeOptions: [],
      YearCodeOptionsLoading: false,

      TeamCodeRules: [(v) => !!v || "Team is required"],
      TeamCode: "",
      TeamCodeOptions: [],
      TeamCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      DesignationIdRules: [(v) => !!v || "Designation is required"],
      DesignationId: "",
      DesignationIdOptions: [],
      DesignationIdOptionsLoading: false,

      MemberNameRules: [(v) => !!v || "Member Name is required"],
      MemberName: "",

      EmailIdRules: [],
      EmailId: "",

      MobileNoRules: [],
      MobileNo: "",

      Address: "",

      CityNameRules: [],
      CityName: "",

      uploadedImages: [],

      FacebookRules: [],
      Facebook: "",

      TwitterRules: [],
      Twitter: "",

      InstagramRules: [],
      Instagram: "",

      MemberTypeId: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = NextJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.pageData();
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    rows: function () {
      console.log("watch rows");
      var n1 = this.rows.length;
      var flag = n1 == 0 ? 0 : 1;
      console.log("n1=" + n1 + ", flag=" + flag);
      this.records = this.rows[0];
    },
    YearCodeOptions: function () {
      this.YearCodeOptionsLoading = false;
    },
    TeamCodeOptions: function () {
      this.TeamCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      this.ZoneCodeOptionsLoading = false;
    },
    MemberIdOptions: function () {
      this.MemberIdOptionsLoading = false;
    },
    DesignationIdOptions: function () {
      this.DesignationIdOptionsLoading = false;
    },
    DesignationId: function () {
      console.log("watch DesignationId");
      var DesignationId = this.DesignationId;
      if (DesignationId != "") {
        var MemberTypeId = "";
        switch (DesignationId) {
          case 14:
            // Junior Jaycee Wing
            MemberTypeId = 9;
            break;

          case 15:
            // Jayceert Wing
            MemberTypeId = 8;
            break;

          default:
            MemberTypeId = 1;
            break;
        }
        this.MemberTypeId = MemberTypeId;
        this.getMemberIdOptions();
      }
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    hideMemberDocumentPrompt(flag) {
      console.log("hideMemberDocumentPrompt called");
      console.log({ flag });
      this.memberDocumentPrompt = false;
      if (flag) {
        this.SelectedMember.document_flag = true;
      }
    },
    hideMemberPhotoPrompt(flag) {
      console.log("hideMemberPhotoPrompt called");
      console.log({ flag });
      this.memberPhotoPrompt = false;
      if (flag) {
        this.SelectedMember.photo_flag = true;
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lgb_dis",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getDesignationIdOptions();
    },
    validate() {
      console.log("validate called");
      this.$refs.form1.validate();
    },
    reset() {
      console.log("reset called");
      this.$refs.form1.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt");
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
    },
    onFileSelected(event, k) {
      console.log("onFileSelected called");
      var n1 = this.uploadedImages.length;
      var file = event.target.files[0];
      console.log("k=" + k + ", event=" + JSON.stringify(file));
      this.uploadedImages[k] = file;
    },
    addNewRow(tableId) {
      console.log("addNewRow called");
      console.log("tableId=" + tableId);

      if (tableId == 2) {
        var n1 = this.AllMembers.length;
        console.log("n1=" + n1);
        // if (n1 < 30) {
        this.AllMembers.push({
          YearwiseOfficesHeldId: null,
          MemberId: 0,
          MemberName: "",
          DesignationId: 0,
          Designation: "",
        });
        // }
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        if (tableId == 2) {
          this.AllMembers.splice(index, 1);
        }
      }
    },
    getDesignationIdOptions() {
      console.log("getDesignationIdOptions called");
      this.DesignationIdOptionsLoading = true;
      var selectbox1_source = "DesignationId";
      var selectbox1_destination = "DesignationIdOptions";
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        OrganizationTypeId: 3,
        ExceptDesignationId: [1, 5, 6, 7],
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        LomCode: this.LomCode,
        MemberType: this.MemberTypeId,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberDetails() {
      console.log("getMemberDetails called");
      this.AllMembers = [];
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.SelectedMember = member;
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      var photo_flag = this.SelectedMember.photo_flag;
      var document_flag = this.SelectedMember.document_flag;
      var dob = this.SelectedMember.member_dob == undefined ? "" : this.SelectedMember.member_dob;
      console.log({ validate1 }, { document_flag }, { photo_flag }, { dob });
      if (validate1 && photo_flag && document_flag && dob != "") {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (dob == "") {
          message += "Kindly fill the Date-Of-Brith. ";
        }
        if (!photo_flag) {
          message += "Kindly upload photo. ";
        }
        if (!document_flag) {
          message += "Kindly upload age proof. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      // add todo in store and clear all fields in dialog
      console.log("submitForm called");

      // this.resetMessageTxt();

      var result = this.$refs.form1.validate();
      console.log("result=" + result);

      var LomCode = this.LomCode;
      var MemberId = this.MemberId;
      var SelectedMember = this.SelectedMember;
      // var n1 = AllMembers.length;

      var CurrentYearId = this.CurrentYearId;
      console.log("CurrentYearId=" + CurrentYearId);

      if (result && CurrentYearId > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url =
          server_url + "api/year-wise-designation/new-governing-board";
        var upload = {
          UserInterface: 1,
          OrganizationTypeId: 3,
          YearCode: CurrentYearId,
          ZoneCode: this.ZoneCode,
          LomCode: this.LomCode,
          MemberId: this.MemberId,
          DesignationId: this.DesignationId,
          DateOfBirth: SelectedMember.member_dob,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            // thisIns.ProgessStart = 0;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.SuccessMessageTxtFlag = 1;
              // thisIns.SuccessMessageTxt = output;

              thisIns.closePrompt();
              // thisIns.AllMembers = [];
              // thisIns.ResultFlag = false;
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "";
        if (LomCode == "") {
          message += "LOM should not be empty. ";
        }
        if (MemberId == "") {
          message += "Member should not be empty. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    console.log("beforeMount called");
    var LomCode = this.$session.get("LomId");
    LomCode = LomCode == (null || undefined) ? "" : LomCode;
    console.log("LomCode=" + LomCode);

    var ZoneCode = this.$session.get("ZoneId");
    ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
    console.log("ZoneCode=" + ZoneCode);

    this.LomCode = LomCode;
    this.ZoneCode = ZoneCode;
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#new-lgb-team-members-modifiy {
  .v-dialog > .v-card > .v-toolbar {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .notes-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 16px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>