<template>
  <div id="add-profession">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
          <v-row wrap>
            <v-col cols="12" sm="12" md="12" lg="12">
              <h4 class="text-danger">Upload File Instructions:</h4>
              <h6>
                1. The DIS form must be filled and signed by the IPP <br />
                2. The filled DIS form should be uploaded in PDF format only
                <br />
                3. The file size should not exceed 2 MB
              </h6>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              class="text-center"
              v-if="OldDocumentFlag"
            >
              <h5 class="text-dark">
                Download Your Old
                <a :href="rows.DocumentAssetPath" target="_blank">
                  Directory Information Document
                </a>
                here
              </h5>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
              <v-file-input
                :clearable="false"
                truncate-length="25"
                accept="application/pdf"
                placeholder="Upload pdf"
                v-model="UploadedFile1"
                prepend-icon="mdi-file-pdf"
              >
                <template v-slot:append-outer>
                  <v-icon color="red" @click="UploadedFile1 = {}">
                    mdi-delete
                  </v-icon>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <v-btn
                      @click="closePrompt"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#a52a2a"
                      class="font-size-h6 px-10 mr-3 white--text"
                    >
                      Close
                    </v-btn> -->
              <v-btn
                :disabled="!valid1"
                @click.prevent="submitForm"
                :loading="SubmitFlag"
                elevation="30"
                shaped
                tile
                small
                color="#1db954"
                class="font-size-h6 px-10 mr-3 white--text"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
          <upload-progress
            :ProgessStart="progessStart"
            :UploadPercentage="uploadPercentage"
          ></upload-progress>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      UploadFlag: false,

      progessStart: 0,
      uploadPercentage: 0,

      rows: [],
      member: {},
      OldDocumentFlag: false,

      UploadedFile1: {},

      DocumentTypeRules: [(v) => !!v || "Document Type is required"],
      DocumentType: "",
      DocumentTypeOptions: [],
      DocumentTypeOptionsLoading: false,

      ReferenceNo: "",
      ReferenceNoRules: [(v) => !!v || "Reference No is required"],
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    resetUploadFile() {
      console.log("resetUploadFile called.");
      this.UploadFile = {};
    },
    closePrompt() {
      console.log("closePrompt called");
      // this.$emit('Member', this.NewMember);
      this.$emit("hideAddRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var validate = true;

      if (validate) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-document/show";
        var upload = {
          UserInterface: 1,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.RecordFlag = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            thisIns.OldDocumentFlag = flag;

            if (flag == 1) {
              console.log("records=" + JSON.stringify(records));

              thisIns.rows = records;

              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      console.log("submitForm called");
      var validate1 = this.$refs.form1.validate();
      // var validate1 = true;
      console.log("validate1=" + validate1);
      var message = "";

      if (validate1) {
        var form = {};

        let upload = new FormData();
        upload.append("UserInterface", 1);
        upload.append("Form", JSON.stringify(form));
        upload.append("uploadFile1", this.UploadedFile1);

        console.log("upload=" + JSON.stringify(upload));

        this.SubmitFlag = true;
        this.progessStart = 1;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-document/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.resetForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill required fields.";
        }
        if (!uploadFile1) {
          message += "File is not selected";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.member = {};
  },
};
</script>
<style lang="scss">
#add-profession {
}
</style>